@use 'styles/base/mixins';
@use 'styles/base/variables';

.accordion {
  margin: variables.$spacing-gdds-section-mobile-small;

  .headline {
    h2 {
      margin-bottom: 1.5rem;
    }

    margin-bottom: 2rem;
  }

  @include mixins.screen-md {
    margin: variables.$spacing-gdds-section-small;
  }

  .accordionItemElement {
    h3[data-testid='accordion-title-elements'] {
      text-transform: unset;
    }
  }

  &.faqAccordion {
    .accordionItem {
      * {
        text-transform: unset;
      }

      p {
        font-size: 1rem;
        max-width: unset;
      }

      :global(.xy) & {
        p:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        h3 {
          font-weight: 500;
        }

        &Content {
          margin: 1.5rem 0 2rem 0;
        }
      }
    }

    h2 {
      margin-bottom: 0.625rem;

      @media (min-width: variables.$gb_breakpointSM) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .handleLink {
    float: right;

    &:hover {
      cursor: pointer;
    }
  }

  &.greyBackground {
    background-color: variables.$gb_grey_050;
    margin: 0;
    padding: variables.$spacing-gdds-section-mobile-small;

    @include mixins.screen-md {
      padding: variables.$spacing-gdds-section-small;
    }
  }

  &.whiteBackground {
    background-color: variables.$gb_white;
  }

  .textWrapper {
    margin-bottom: 2rem;

    p {
      font-size: 1rem;
      line-height: 1.5rem;
      max-width: unset;
    }
  }

  // get rid of grid margin
  .content {
    margin: 0 -1rem;

    @include mixins.screen-md {
      margin: 0 -1.5rem;
    }

    :global(.c-tpp-area) {
      > div {
        margin-top: 0.5rem;
      }
    }
  }

  :global(.twyford) & {
    h2 {
      font-weight: variables.$font-weight-bold;

      span {
        font-weight: variables.$font-weight-bold;
      }

      span[class*='headline_subtitle'] {
        font-weight: variables.$font-weight-light;

        span {
          font-weight: variables.$font-weight-light;
        }
      }
    }
  }
}
