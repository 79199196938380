@use 'styles/base/mixins';
@use 'styles/base/variables';

.accWrapper {
  padding: 0 0.9375rem;

  @include mixins.screen-md {
    padding: 0 variables.$spacing-xxl;
  }

  &.light-greyBackground {
    background-color: variables.$gb_grey_060;
  }

  &.blue-greyBackground {
    background-color: variables.$gb_dark-grey-blue;
    color: variables.$gb_white;
  }

  &.medium-greyBackground {
    background-color: variables.$gb_grey_100;
  }
}

.light-greyBackground {
  background-color: variables.$gb_grey_060;
}

.blue-greyBackground {
  background-color: variables.$gb_dark-grey-blue;
  color: variables.$gb_white;
}

.medium-greyBackground {
  background-color: variables.$gb_grey_100;
}

.whiteBackground {
  :global(.c-product-tile a) {
    border: variables.$gb_grey_060 solid 4px;
  }
}

:global(.c-product-tile) {
  min-width: 178px;
  @include mixins.screen-md {
    min-width: 225px;
  }
}

.okvAccordionTitle {
  padding: 1.5rem 1rem;
}

.okvContainer {
  padding: 3.75rem 0 0;
}

.isAssortment {
  :global(h6),
  :global(.h6) {
    font-size: 0.8125rem;
    margin-bottom: 0;
  }

  :global(.c-accordion__title) {
    margin-bottom: 0;
  }

  :global(.c-product-tiles__container) {
    padding-top: 0;
  }

  :global(.c-accordion > div:not(:last-child) > .c-accordion__item) {
    // fully removing the border will change the behaviour of the background
    border-bottom-color: transparent;
  }

  :global(.c-product-tile__title) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  :global(.c-product-tile__articles) {
    font-size: 0.875rem;
  }
}

// non-standard headline for the asset section
.assetHeadline h2 {
  font-size: 1.5rem;
  line-height: 1.625rem;
  margin-bottom: 1.875rem;

  @include mixins.screen-md {
    font-size: 1.625rem;
    line-height: 2rem;
  }
}
