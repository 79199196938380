@use 'styles/base/mixins';
@use 'styles/base/variables';

.accordion {
  margin: variables.$spacing-gdds-section-mobile-small;

  @include mixins.screen-md {
    margin: variables.$spacing-gdds-section-small;
  }

  h2 {
    margin-bottom: 0.625rem;

    @media (min-width: variables.$gb_breakpointSM) {
      margin-bottom: 1.5rem;
    }
  }

  .handleLink {
    float: right;

    &:hover {
      cursor: pointer;
    }
  }

  &.greyBackground {
    background-color: variables.$gb_grey_050;
    margin: 0;
    padding: variables.$spacing-gdds-section-mobile-small;

    @include mixins.screen-md {
      padding: variables.$spacing-gdds-section-small;
    }
  }

  &.whiteBackground {
    background-color: variables.$gb_white;
  }

  // get rid of grid margin
  .content {
    margin: 0 -1rem;

    @include mixins.screen-md {
      margin: 0 -1.5rem;
    }
  }
}
